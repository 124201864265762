import { createBrowserRouter } from "react-router-dom";
import Main from "../../Layout/Main";
import Home from "../../pages/Home";
import College from "../../pages/College";
import SignIn from "../../pages/SignIn";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import Add_College from "../../pages/Add_College";
import Add_Division from "../../pages/Add_division";
import Division from "../../pages/Division";
import Add_State from "../../pages/Add_state";
import State from "../../pages/State";
import Course from "../../pages/Course";
import Add_Course from "../../pages/Add_course";
import Add_Exam from "../../pages/Add_exam";
import Exam from "../../pages/Exam";
import Location from "../../pages/Location";
import Add_Announcement from "../../pages/Add_announcement";
import Announcement from "../../pages/Announcement";
import Notification from "../../pages/Notification";
import Add_Notification from "../../pages/Add_notification";
import Hiring from "../../pages/Hiring";
import Add_Hiring from "../../pages/Add_hiring";
import Notice from "../../components/Notice/Notice";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <PrivateRoute>
        <Main></Main>
      </PrivateRoute>
    ),

    children: [
      {
        path: "/",
        element: <Notice></Notice>,
      },

      {
        path: "/add_college",
        element: <Add_College></Add_College>,
      },

      {
        path: "/college_list",
        element: <College></College>,
      },
      {
        path: "/add_course",
        element: <Add_Course></Add_Course>,
      },
      {
        path: "/course_list",
        element: <Course></Course>,
      },
      {
        path: "/add_exam",
        element: <Add_Exam></Add_Exam>,
      },
      {
        path: "/exam_list",
        element: <Exam></Exam>,
      },
      {
        path: "/add_division",
        element: <Add_Division></Add_Division>,
      },
      {
        path: "/division_list",
        element: <Division></Division>,
      },
      {
        path: "/add_state",
        element: <Add_State></Add_State>,
      },
      {
        path: "/states_list",
        element: <State></State>,
      },
      {
        path: "/division_list",
        element: <Division></Division>,
      },
      {
        path: "/location",
        element: <Location></Location>,
      },

      {
        path: "/add_announcement",
        element: <Add_Announcement></Add_Announcement>,
      },
      {
        path: "/announcement_list",
        element: <Announcement></Announcement>,
      },
      {
        path: "/add_notication",
        element: <Add_Notification></Add_Notification>,
      },
      {
        path: "/notication_list",
        element: <Notification></Notification>,
      },
      {
        path: "/announcement_list",
        element: <Announcement></Announcement>,
      },
      {
        path: "/add_hiring",
        element: <Add_Hiring></Add_Hiring>,
      },
      {
        path: "/hiring_list",
        element: <Hiring></Hiring>,
      },
      // {
      //   path: "/notice",
      //   element: <Notice></Notice>,
      // },
    ],
  },
  {
    path: "/signup",
    element: <SignIn></SignIn>,
  },
]);

export default router;
