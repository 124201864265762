import React, { useEffect, useState } from "react";
import img from "../../../img/college/Icon material-delete.png";
import img3 from "../../../img/college/Icon feather-edit.png";
import axios from "axios";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ToolkitProvider, {
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";

import Announcement from "../../../pages/edit/Announcement";
import { useQuery } from "react-query";

const MyExportCSV = (props) => {
  const handleClick = () => {
    props.onExport();
  };

  return (
    <div>
      <button className="college_btn  mb-2 p-3" onClick={handleClick}>
        Export to CSV
      </button>
    </div>
  );
};

const Announcement_list = () => {
  const MySwal = withReactContent(Swal);

  const { data, error, refetch } = useQuery("announcementData", async () => {
    const response = await axios.get(
      "https://educationapi.pino10.shop/api/announcement",
      {
        mode: "cors",
      }
    );
    return response.data;
  });

  useEffect(() => {
    if (error) {
      console.error("Error fetching announcement data:", error);
    }
  }, [error, data]);

  const { ExportCSVButton } = CSVExport;

  const columns = [
    {
      text: "No",
      formatter: (cellContent, row, index) => {
        return (
          <>
            {" "}
            <p>{index + 1}</p>
          </>
        );
      },
    },

    {
      dataField: "name",
      text: "Announcement Name",
    },
    {
      dataField: "description",
      text: " Description",
    },
    {
      text: "Date",
      formatter: (cellContent, row) => {
        return (
          <>
            {" "}
            <div className="d-flex justify-content-center">
              <p>{row.date ? row.date.slice(0, 10) : ""}</p>
            </div>
          </>
        );
      },
    },

    {
      text: "Action",
      formatter: (cellContent, row) => {
        return (
          <>
            {" "}
            <div className="d-flex justify-content-center">
              <img
                src={img3}
                alt=""
                data-toggle="modal"
                data-target={`#loginModal${row._id}`}
              />
              <img
                src={img}
                alt=""
                className="ms-3"
                onClick={() => handleCategory(row._id)}
              />
            </div>
            <div
              className="modal fade"
              id={`loginModal${row._id}`}
              tabIndex="{-1}"
              role="dialog"
              aria-labelledby="loginModal"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content" style={{ width: 700 }}>
                  <div className="modal-body">
                    <Announcement data={row} />
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      },
    },
  ];
  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    style: { width: 60 },
    lastPageText: "Last",
    firstPageText: "First",
    nextPageText: "Next",
    prePageText: "Previous",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
  });

  //delete
  const [products, setProducts] = useState(data);
  const handleCategory = async (id) => {
    const confirmation = window.confirm("Are you Sure?");
    if (confirmation) {
      const url = `https://educationapi.pino10.shop/api/announcement/${id}`;
      fetch(url, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          MySwal.fire("Good job!", "successfully deleted", "success");
          if (data.deletedCount === 1) {
            const remainItem = products.filter((item) => item._id !== id);
            setProducts(remainItem);
          }
        });
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-7">
          <h6 className="college_h6">Announcement List</h6>
        </div>
      </div>
      <hr style={{ height: "1px", background: "rgb(191 173 173)" }} />
      <div className="card">
        <div className="card-body card_body_sm">
          <>
            <ToolkitProvider
              bootstrap4
              keyField="_id"
              columns={columns}
              data={data || []}
              pagination={pagination}
              exportCSV
            >
              {(props) => (
                <React.Fragment>
                  <MyExportCSV {...props.csvProps} />

                  <BootstrapTable
                    bootstrap4
                    keyField="_id"
                    columns={columns}
                    data={data || []}
                    pagination={pagination}
                    {...props.baseProps}
                  />
                </React.Fragment>
              )}
            </ToolkitProvider>
          </>
        </div>
      </div>
    </div>
  );
};

export default Announcement_list;
