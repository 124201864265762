import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const Add_College = () => {
  const [files, setFiles] = useState("");
  const MySwal = withReactContent(Swal);
  const [data, setData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await axios.get(
          `https://educationapi.pino10.shop/api/division`,
          {
            mode: "cors",
          }
        );
        setData(data);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);
  const [data2, setData2] = useState([]);
  useEffect(() => {
    const getData2 = async () => {
      try {
        const { data } = await axios.get(
          `https://educationapi.pino10.shop/api/states`,
          {
            mode: "cors",
          }
        );
        setData2(data);
      } catch (error) {
        console.log(error);
      }
    };
    getData2();
  }, []);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data2 = {
      name: formData.get("name"),
      division: {
        id: formData.get("divisionId"),
      },
      states: {
        id: formData.get("statesId"),
      },
      address: formData.get("address"),
      established_date: formData.get("established_date"),
      status: formData.get("status"),
      mail_id: formData.get("mail_id"),
    };
    try {
      const list = await Promise.all(
        Object.values(files).map(async (file) => {
          const data = new FormData();
          data.append("file", file);
          data.append("upload_preset", "upload");
          const uploadRes = await axios.post(
            "https://api.cloudinary.com/v1_1/dtpvtjiry/image/upload",
            data
          );

          const { url } = uploadRes.data;
          return url;
        })
      );

      const product = {
        ...data2,
        img: list,
      };

      await axios.post("https://educationapi.pino10.shop/api/college", product);
      MySwal.fire("Good job!", "successfully added", "success");
    } catch (err) {
      MySwal.fire("Something Error Found.", "warning");
    }
  };
  return (
    <div className="wrapper">
      <div className="content-wrapper" style={{ background: "unset" }}>
        <div className="registration_div card">
          <form onSubmit={handleSubmit}>
            <div className="row p-3">
              <div className="col-md-12 form_sub_stream">
                <label
                  htmlFor="inputState"
                  className="form-label profile_label3 "
                >
                  College Name
                </label>

                <input
                  type="text"
                  className="main_form w-100"
                  name="name"
                  placeholder="College Name"
                />
              </div>
              <div className="col-md-6 form_sub_stream">
                <label
                  htmlFor="inputState"
                  className="form-label profile_label3 "
                >
                  College Mail Id
                </label>

                <input
                  type="email"
                  className="main_form w-100"
                  name="mail_id"
                  placeholder="College Mail Id"
                />
              </div>
              <div className="col-md-6 form_sub_stream">
                <label
                  htmlFor="inputState"
                  className="form-label profile_label3 "
                >
                  College Stablished Date
                </label>

                <input
                  type="text"
                  className="main_form w-100"
                  name="established_date"
                  placeholder="College Stablished Date"
                />
              </div>
              <div className="col-md-6 form_sub_stream ">
                <label htmlFor="inputState" className="profile_label3">
                  Division
                </label>
                <select
                  name="divisionId"
                  id="inputState"
                  className="main_form w-100"
                >
                  <option selected>Select Division</option>
                  {data.map((pd) => (
                    <option key={pd._id} value={pd._id}>
                      {pd.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6 form_sub_stream ">
                <label htmlFor="inputState" className="profile_label3">
                  States
                </label>
                <select
                  name="statesId"
                  id="inputState"
                  className="main_form w-100"
                >
                  <option selected>Select States</option>
                  {data2.map((pd) => (
                    <option key={pd._id} value={pd._id}>
                      {pd.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6 form_sub_stream">
                <label className="profile_label3">Status</label>
                <select
                  id="inputState"
                  name="status"
                  className="main_form w-100"
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>
              <div className="col-md-6 form_sub_stream">
                <label
                  htmlFor="inputState"
                  className="form-label profile_label3 "
                >
                  College Address
                </label>

                <input
                  type="text"
                  className="main_form w-100"
                  name="address"
                  placeholder="College Stablished Date"
                />
              </div>
              <div className="col-md-12 form_sub_stream">
                <label
                  htmlFor="inputState"
                  className="form-label profile_label3 "
                >
                  Image upload
                </label>

                <input
                  type="file"
                  className="main_form w-100 p-0"
                  name="img"
                  onChange={(e) => setFiles(e.target.files)}
                  multiple
                />
              </div>
            </div>

            <div className="d-flex justify-content-center my-5">
              <button
                type="submit"
                className="profile_btn"
                style={{ width: 175 }}
              >
                Add College
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Add_College;
