import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Exam_list from "../components/Exam/Exam_list";
const Exam = () => {
  return (
    <div>
      <Exam_list />
    </div>
  );
};

export default Exam;
