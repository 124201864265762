import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Division_list from "../components/Locations/Division/Division";

const Division = () => {
  return (
    <div>
      <Division_list />
    </div>
  );
};

export default Division;
