import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import img from "../../../img/home/Group 1332.png";
import img2 from "../../../img/home/Icon awesome-caret-down-1.png";
import img3 from "../../../img/home/Ellipse 116.png";
import img6 from "../../../img/home/noun-stream-4701152.png";
import img7 from "../../../img/home/noun-stream-play-5240252.png";

import img9 from "../../../img/home/Group 1329.png";
import img13 from "../../../img/home/log.png";

import { UilSearch } from "@iconscout/react-unicons";
import "./Navbar.css";
import { useContext } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { AuthContext } from "../../../contexts/UserProvider";

const Navbar = () => {
  const { user, logoutUser } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const handleLogOut = () => {
    logoutUser()
      .then(() => {
        navigate("/signup");
      })
      .catch((err) => console.log(err));
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  if (location.pathname === "/signup") {
    return null;
  }
  return (
    <div>
      <div className="wrapper">
        <nav className="main-header navbar navbar-expand">
          {/* Left navbar links */}
          <div className="nav_design ms-3">
            <ul className="navbar-nav" style={{ marginTop: -6 }}>
              <div className="navbar_bar">
                <li className="nav-link">
                  <a
                    className="nav-link"
                    data-widget="pushmenu"
                    href="#"
                    role="button"
                  >
                    <i className="fas fa-bars bars_1" />
                  </a>
                </li>
              </div>
            </ul>
            <ul
              style={{ zIndex: 1 }}
              className="nav navbar-nav navbar-right nav_bar_icons menu_right_li"
            >
              <li className="new_invoice_top_menu_link_li">
                <div className="logoSearch">
                  <div className="search">
                    <input type="text" placeholder="Search here" />
                    <div className="s-icon">
                      <UilSearch />
                    </div>
                  </div>
                </div>
              </li>
            </ul>

            <ul className="navbar-nav ml-lg-auto">
              <div>
                <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <img src={img3} className="profile_image" alt="" />
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleClose}>Profile</MenuItem>
                  <MenuItem onClick={handleClose}>My account</MenuItem>
                  <MenuItem onClick={handleLogOut}>Logout</MenuItem>
                </Menu>
              </div>
              {/* <div className="d-flex mt-lg-3">
                {" "}
                <li className="">
                  <a onClick={handleLogOut} style={{ cursor: "pointer" }}>
                    <img
                      src={img}
                      className="log_out_sm"
                      alt=""
                      style={{ marginTop: -2 }}
                    />
                  </a>
                </li>
                <li className="ms-2">
                  <a className="log_out_sm">
                    <span
                      className="navbar_span me-2 "
                      style={{ fontWeight: 600 }}
                    >
                      {" "}
                      Admin
                    </span>

                    <img src={img2} alt="" />
                  </a>

               
                </li>
              </div>

              <div className="mt-lg-3">
                <img src={img3} className="profile_image" alt="" />
              </div> */}
            </ul>
          </div>
          {/* Right navbar links */}
        </nav>

        <aside
          className="main-sidebar sidebar-dark-primary elevation-4 side_menubar"
          style={{
            position: "fixed",
          }}
        >
          {/* Sidebar */}
          <div className="sidebar">
            {/* Sidebar user panel (optional) */}
            <div>
              <h6 className="navbar_logo_text text-center my-4">LOGO</h6>
            </div>

            {/* SidebarSearch Form */}
            {/* <div className="form-inline">
              <div className="input-group" data-widget="sidebar-search">
                <input
                  className="form-control form-control-sidebar"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  style={{ background: "white" }}
                />
                <div className="input-group-append">
                  <button className="btn btn-sidebar">
                    <i className="fas fa-search fa-fw" />
                  </button>
                </div>
              </div>
            </div> */}
            {/* Sidebar Menu */}
            <nav className="mt-2">
              <ul
                className="nav nav-pills nav-sidebar flex-column myDIV"
                data-widget="treeview"
                role="menu"
                data-accordion="false"
              >
                {/* <Link to={"/"}>
                  <li className="main_nav-link">
                    <a href="/" className="nav-link">
                      <i class="fa-sharp fa-solid fa-house span_text2"></i>

                      <div className="menu_flex">
                        <span className="span_text">Home</span>
                      </div>
                    </a>
                  </li>
                </Link> */}
                <Link to={"/"}>
                  <li className="main_nav-link">
                    <a href="/" className="nav-link">
                      <img style={{ width: 16 }} src={img9} alt="" />
                      <div className="menu_flex">
                        <span className="span_text">Home</span>
                      </div>
                    </a>
                  </li>
                </Link>
                <Link to={"/add_college"}>
                  <li className="main_nav-link">
                    <a href="/add_college" className="nav-link">
                      <i class="fa-sharp fa-solid fa-building-columns span_text2"></i>
                      <div className="menu_flex">
                        <span className="span_text">Add College</span>
                      </div>
                    </a>
                  </li>
                </Link>
                <Link to={"/college_list"}>
                  <li className="main_nav-link">
                    <a href="/college_list" className="nav-link">
                      <img style={{ width: 16 }} src={img6} alt="" />
                      <div className="menu_flex">
                        <span className="span_text">College List</span>
                      </div>
                    </a>
                  </li>
                </Link>
                <Link to={"/add_course"}>
                  <li className="main_nav-link">
                    <a href="/add_course" className="nav-link">
                      <i class="fa-sharp fa-solid fa-building-columns span_text2"></i>
                      <div className="menu_flex">
                        <span className="span_text">Add Course</span>
                      </div>
                    </a>
                  </li>
                </Link>
                <Link to={"/course_list"}>
                  <li className="main_nav-link">
                    <a href="/course_list" className="nav-link">
                      <img style={{ width: 16 }} src={img6} alt="" />
                      <div className="menu_flex">
                        <span className="span_text">Course List</span>
                      </div>
                    </a>
                  </li>
                </Link>
                <Link to={"/add_exam"}>
                  <li className="main_nav-link">
                    <a href="/add_exam" className="nav-link">
                      <i class="fa-sharp fa-solid fa-building-columns span_text2"></i>
                      <div className="menu_flex">
                        <span className="span_text">Add Exam</span>
                      </div>
                    </a>
                  </li>
                </Link>
                <Link to={"/exam_list"}>
                  <li className="main_nav-link">
                    <a href="/exam_list" className="nav-link">
                      <img style={{ width: 16 }} src={img6} alt="" />
                      <div className="menu_flex">
                        <span className="span_text">Exam List</span>
                      </div>
                    </a>
                  </li>
                </Link>

                <Link to={"/location"}>
                  <li className="main_nav-link">
                    <a href="/location" className="nav-link">
                      <img style={{ width: 16 }} src={img9} alt="" />
                      <div className="menu_flex">
                        <span className="span_text">Location</span>
                      </div>
                    </a>
                  </li>
                </Link>

                <li className="main_nav-link password_sm">
                  <a className="nav-link" onClick={handleLogOut}>
                    <img style={{ width: 16 }} src={img13} alt="" />
                    <div className="menu_flex">
                      <span className="span_text">Log Out</span>
                    </div>
                  </a>
                </li>
              </ul>
            </nav>
            {/* /.sidebar-menu */}
          </div>
          {/* /.sidebar */}
        </aside>
      </div>
    </div>
  );
};

export default Navbar;
