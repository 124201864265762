import axios from "axios";
import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const Add_Hiring = () => {
  const MySwal = withReactContent(Swal);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data2 = {
      name: formData.get("name"),
      description: formData.get("description"),
      date: formData.get("date"),
    };
    try {
      await axios.post("https://educationapi.pino10.shop/api/hiring", data2);
      MySwal.fire("Good job!", "successfully added", "success");
    } catch (err) {
      MySwal.fire("Something Error Found.", "warning");
    }
  };
  return (
    <div className="wrapper">
      <div className="content-wrapper" style={{ background: "unset" }}>
        <div className="registration_div card">
          <form onSubmit={handleSubmit}>
            <div className="row p-3">
              <div className="col-md-12 form_sub_stream">
                <label
                  htmlFor="inputState"
                  className="form-label profile_label3 "
                >
                  Name
                </label>

                <input
                  type="text"
                  className="main_form w-100"
                  name="name"
                  placeholder="Hiring Name"
                />
              </div>
              <div className="col-md-12 form_sub_stream">
                <label
                  htmlFor="inputState"
                  className="form-label profile_label3 "
                >
                  Description
                </label>

                <input
                  type="text"
                  className="main_form w-100"
                  name="description"
                  placeholder="Hiring Description"
                />
              </div>
              <div className="col-md-12 form_sub_stream">
                <label
                  htmlFor="inputState"
                  className="form-label profile_label3 "
                >
                  Date
                </label>

                <input
                  type="date"
                  className="main_form w-100"
                  name="date"
                  placeholder="Hiring Date"
                />
              </div>
            </div>

            <div className="d-flex justify-content-center my-5">
              <button
                type="submit"
                className="profile_btn"
                style={{ width: 175 }}
              >
                Add Hiring
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Add_Hiring;
