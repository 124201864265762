import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import College_list from "../components/College/College_list";
const College = () => {
  return (
    <div>
      <College_list />
    </div>
  );
};

export default College;
