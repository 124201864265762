import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Notification_list from "../components/Notice/Notification/Notification";

const Notification = () => {
  return (
    <div>
      <Notification_list />
    </div>
  );
};

export default Notification;
