import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Hiring_list from "../components/Notice/Hiring/Hiring";

const Hiring = () => {
  return (
    <div>
      <Hiring_list />
    </div>
  );
};

export default Hiring;
