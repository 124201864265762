import React from "react";
import Locations from "../components/Locations/Locations";

const Location = () => {
  return (
    <div>
      <Locations />
    </div>
  );
};

export default Location;
