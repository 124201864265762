import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Announcement_list from "../components/Notice/Announcement/Announcement";

const Announcement = () => {
  return (
    <div>
      <Announcement_list />
    </div>
  );
};

export default Announcement;
