import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Course_list from "../components/Course/Course_list";
const Course = () => {
  return (
    <div>
      <Course_list />
    </div>
  );
};

export default Course;
