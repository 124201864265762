import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import States_list from "../components/Locations/State/State";

const State = () => {
  return (
    <div>
      <States_list />
    </div>
  );
};

export default State;
